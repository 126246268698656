import TyExpertsSimple from "src/components/Survey/TyExpertsSimple";
import TyHomevestorsSimple from "src/components/Survey/TyHomevestorsSimple";
import HomevestorsLayout from "src/layouts/homevestors-layout";
import ServiceExpertsLayout from "src/layouts/serviceexperts-layout";
import {getCurrentUser} from "../../../shared/services/session-service";
import {HOMEVESTORS, SERVICEEXPERTS} from "../../../models/survey-company";

const ThankyouSimple = () => {
  const user  = getCurrentUser();  
  return (
      <>
      {user?.companyId === HOMEVESTORS && (
          <HomevestorsLayout>
          <TyHomevestorsSimple />
        </HomevestorsLayout>
      )}
      {user?.companyId === SERVICEEXPERTS  && (
        <ServiceExpertsLayout>
          <TyExpertsSimple />
        </ServiceExpertsLayout>
      )}
    </>
  );
};

export default ThankyouSimple;
