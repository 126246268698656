import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { HttpRequestError } from "../../models/http-request-error";
import { Option } from "../../models/option";
import { logout, setToken } from "../../shared/services/session-service";
import { getStates } from "../../shared/services/state";
import { authenticateHomevestors } from "../../shared/services/survey-service";
import LoadingButton from "./LoadingButton";
import {postVisitorTracking} from "../../shared/services/visitor-tracking-service";

const HomeVestorsForm = () => {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [satelliteCode, setSatelliteCode] = useState<string | null>();
  const [respondentCode, setRespondentCode] = useState<string | null>();
  const [states, setStates] = useState<Option<number>[]>([]);
  const fetchStates = async () =>{
    const states = await getStates();
    setStates(states);
  }
  const [isLoading, setIsLoading] = useState(false);

  const sendVisitorTracking = async () =>{
    await postVisitorTracking({
      companyId: 31,
      pageName: 'Customer Information',
      satelliteCode: searchParams.get('scode') ?? undefined
    });
  }
  
  useEffect(() => {
    logout();
    setSatelliteCode(searchParams.get('scode'));
    setRespondentCode(searchParams.get('rcode'));
    fetchStates();
    sendVisitorTracking();
  }, []);

  const initialValues = {
    lastName: "",
    firstName: "",
    emailAddress: "",
    city: "",
    state: ""
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    try{
      if(satelliteCode && respondentCode){
        const response = await authenticateHomevestors({
          satelliteCode: satelliteCode,
          respondentCode: respondentCode,
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress,
          city: values.city,
          state:  parseInt(values.state),
          source: searchParams.get('source')?? ''
        });
        setToken(response.token);
        navigate("/survey");
      }      
    }catch (error) {
      if (error instanceof HttpRequestError) {
        swal.fire({
          text: error.message,
          toast: true,
          icon: "error",
          position: "top-right",
          timer: 3000,
        });
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(!satelliteCode || !respondentCode) &&
          <h2 className="max-w-lg mx-auto my-0 mb-3 text-3xl font-medium leading-9 text-center md:font-medium md:text-4xl">
            The link you received is not correct.
          </h2>}
      
      {(satelliteCode && respondentCode) &&
          <>
            <h2 className="max-w-lg mx-auto my-0 mb-3 text-3xl font-medium leading-9 text-center md:font-medium md:text-4xl">
              Please verify or update your contact information
            </h2>
            <p className="max-w-3xl mx-auto my-0 mb-4 text-center">
              To ensure we have the most accurate information in our records, please
              complete the following form.
            </p>
            <div className="container flex-col justify-center mx-auto mb-20 mt-7 md:max-w-2xl">
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors }) => (
                    <Form className="px-10 py-5 mb-5 bg-white rounded-lg shadow">
                      <div className="my-5">
                        <label className="flex" htmlFor="firstName">
                          First Name*
                        </label>
                        <Field
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                        />
                        <ErrorMessage
                            name="firstName"
                            component={() => (
                                <div className="flex items-end mt-2 ">
                                  <ExclamationTriangleIcon
                                      className="mt-1 mr-2 text-red-500"
                                      width="15"
                                      height="15"
                                  />
                                  <p className="text-sm text-red-500">{errors.firstName}</p>
                                </div>
                            )}
                        />
                      </div>
                      <div className="my-5">
                        <label className="flex" htmlFor="lastName">
                          Last Name*
                        </label>
                        <Field
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                        />
                        <ErrorMessage
                            name="lastName"
                            component={() => (
                                <div className="flex items-end mt-2 ">
                                  <ExclamationTriangleIcon
                                      className="mt-1 mr-2 text-red-500"
                                      width="15"
                                      height="15"
                                  />
                                  <p className="text-sm text-red-500">{errors.lastName}</p>
                                </div>
                            )}
                        />
                      </div>
                      <div className="my-5">
                        <label className="flex" htmlFor="emailAddress">
                          Email*
                        </label>
                        <Field
                            name="emailAddress"
                            placeholder="Email"
                            type="text"
                            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                        />
                        <ErrorMessage
                            name="emailAddress"
                            component={() => (
                                <div className="flex items-end mt-2 ">
                                  <ExclamationTriangleIcon
                                      className="mt-1 mr-2 text-red-500"
                                      width="15"
                                      height="15"
                                  />
                                  <p className="text-sm text-red-500">
                                    {errors.emailAddress}
                                  </p>
                                </div>
                            )}
                        />
                      </div>
                      <div className="my-5">
                        <label className="flex" htmlFor="city">
                          City*
                        </label>
                        <Field
                            name="city"
                            placeholder="City"
                            type="text"
                            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                        />
                        <ErrorMessage
                            name="city"
                            component={() => (
                                <div className="flex items-end mt-2 ">
                                  <ExclamationTriangleIcon
                                      className="mt-1 mr-2 text-red-500"
                                      width="15"
                                      height="15"
                                  />
                                  <p className="text-sm text-red-500">{errors.city}</p>
                                </div>
                            )}
                        />
                      </div>
                      <div className="my-5">
                        <label className="flex" htmlFor="state">
                          State/Province*
                        </label>
                        <Field
                            className="w-full p-3 mt-3 border rounded-xl bg-gray-50"
                            as="select"
                            name="state"
                        >
                          <option value="0">---Select One---</option>
                          {states?.map((state: any) => (
                              <option key={state.value} value={state.value}>
                                {state.text}
                              </option>
                          ))}
                        </Field>
                      </div>
                      <LoadingButton
                          buttonText="Submit"
                          value="Next"
                          isLoading={isLoading}
                      />
                    </Form>
                )}
              </Formik>
            </div>
          </>
          }
      
    </>
  );
};

export default HomeVestorsForm;
