import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "src/components/Admin/Header";
import AddNotificationModal from "src/components/Admin/Satellites/AddNotificationModal";
import NotificationsTable from "src/components/Admin/Satellites/NotificationsTable";
import { USER_TYPE_INTERNAL } from "src/models/user";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../../../src/shared/services/session-service";
import { SatelliteProvider } from "../SatelliteProvider";

const Notifications = () => {
  const [selectedId, setselectedId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const user = getCurrentUser();
  const navigate = useNavigate();

  const changeNewCompanyId = (value: any) => {
    setSelectedCompanyId(value);
    setselectedId(0); // reset selected satellite id every time company id changes
  };

  const changeNewId = (value: any) => {
    setselectedId(value);
  };

  const refreshData = () => {
    setRefreshCount((count) => count + 1);
  };
  

  const companyId = user?.companyId;
  const clientId = user?.clientId;
  const userId = user?.userId;
  const userIdNumber = Number(userId);

  useEffect(() => {
    if (!isAuthenticated(USER_TYPE_INTERNAL)) {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      <SatelliteProvider>
        <Header
          changeSatelliteId={changeNewId}
          changeCompanyId={changeNewCompanyId}
        />
        <div className="flex items-center justify-between mx-4 mt-4 mb-2">
          <h2 className="text-xl font-semibold sm:text-2xl text-slate-600">
            Satellite Notifications
          </h2>
          <AddNotificationModal
            companyId={companyId}
            userId={userIdNumber}
            onAddSatelliteNotification={refreshData}
          />
        </div>
        <div>
          <div className="mx-4">
            <NotificationsTable
              companyId={selectedCompanyId}
              userId={userIdNumber}
              satelliteId={selectedId}
              clientId={clientId}
              refreshCount={refreshCount}
            />
          </div>
        </div>
      </SatelliteProvider>
    </>
  );
};

export default Notifications;
