import { PowerIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import clogo from "src/images/commendlogo.png";
import { SatelliteProvider } from "src/pages/admin/SatelliteProvider";
import {
  getCurrentUser,
  removeToken,
} from "../../shared/services/session-service";
import DashboardDropdowns from "./DashboardDropdowns";
import MyDropdown from "./MyDropdown";
import SatellitesDropdowns from "./Satellites/SatellitesDropdowns";

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    removeToken();
    navigate("/admin");
  };
  const user = getCurrentUser();
  return (
    <>
      <div className="flex flex-wrap items-center justify-between w-full px-4 mt-4 bg-white">
        {/* //logo */}
        <div>
          <a
            className="text-white no-underline hover:text-white hover:no-underline"
            href="/admin/dashboard"
          >
            <span className="pl-2 text-2xl">
              <button type="button">
                <img
                  src={clogo}
                  alt="commend logo"
                  width="120px"
                  height="50%"
                />
              </button>
            </span>
          </a>
        </div>

        {/* //logged in */}
        <div>
          <div className="mr-3">
            <div className="flex">
              <span className="inline-block px-2 py-2 text-sm text-gray-600 no-underline">
                Logged in as {user?.username}
              </span>
              <button onClick={logout}>
                <PowerIcon
                  className="flex hover:text-gray-200"
                  color="#0088cc"
                  width="16px"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav className="flex-grow">
        <div>
          {location.pathname === "/admin/notifications" ? (
            <SatelliteProvider>
              <SatellitesDropdowns {...props} />
            </SatelliteProvider>
          ) : location.pathname === "/admin/satellites" ? (
            <SatelliteProvider>
              <SatellitesDropdowns {...props} />
            </SatelliteProvider>
          ) : location.pathname === "/admin/dashboard" ? (
            <DashboardDropdowns />
          ) : null}
        </div>
      </nav>

      <div>
        <MyDropdown />
      </div>
    </>
  );
};

export default Header;
