import axios from "axios";
import { sub } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../pages/admin/admin-context";
import {
  getClients,
  getCompanies
} from "../../shared/services/internal-user-service";
import DatePicker from "./DatePicker";

const DashboardDropdowns = () => {
  const [client, setClient] = useState<number>(0);
  const [company, setCompany] = useState<number>(0);
  const [satellite, setSatellite] = useState<number>(0);

  const [clients, setClients] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [satellites, setSatellites] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date>(sub(new Date(), { weeks: 1 }));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [loading, setLoading] = useState<boolean>(true); 

  const context = useContext(DashboardContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientResponse, companyResponse] = await Promise.all([
          getClients(),
          getCompanies(),
        ]);
  

        setClients(clientResponse);
        let startupClient = 0;
        if (clientResponse.length == 1) {
          setClient(clientResponse[0].value);
          startupClient = clientResponse[0].value;
        }
        setCompanies(companyResponse);
        let startupCompany = 0;
        if (companyResponse.length == 1) {
          setCompany(companyResponse[0].value);
          startupCompany = companyResponse[0].value;
        }

        if (company !== 0) {
          const response = await axios.get("/api/Satellite/by-company", {
            params: { companyId: company },
          });
          const satelliteResponse = response.data.map((satellite: { satelliteId: any; satelliteName: any; satelliteCode: any; }) => ({
            value: satellite.satelliteId, 
            text: `${satellite.satelliteCode} ${satellite.satelliteName} `
          }));

          setSatellites(satelliteResponse);
          let startupSatellite = 0;
          if (satelliteResponse.length == 1) {
            setSatellite(satelliteResponse[0].value);
            startupSatellite = satelliteResponse[0].value;
          }
          context.updateDashboardParameters(
            startupClient,
            startupCompany,
            startupSatellite,
            startDate,
            endDate
          );
        }
        setLoading(false); // Set loading to false once data has been loaded
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [company, client]);

  
  return (
    <DashboardContext.Consumer>
      {({ updateDashboardId, updateDashboardParameters }) => (
        <div className="flex flex-col content-start px-6 mt-2 mb-4 text-xs lg:flex-row">
              <div className="flex ">
                  <DatePicker
                      label={'Start Date'}
                      initialState={startDate}
                      onChange={(newDate)=> {
                        setStartDate(newDate);
                        updateDashboardParameters(client, company, satellite, newDate, endDate)
                      }
                  }
                  />
                <DatePicker
                    label={'End Date'}
                    initialState={endDate}
                    onChange={(newDate)=>
                    {
                      setEndDate(newDate);
                      updateDashboardParameters(client, company, satellite, startDate, newDate);
                    }                        
                }
                />
              </div>

              <select
                className="flex p-3 mx-1 mt-3 border md:mr-3 rounded-xl bg-gray-50"
                name="client"
                value={client}
                onChange={(oc) => {
                  let newClientId = parseInt(oc.target.value);
                  setClient(newClientId);
                  updateDashboardParameters(newClientId, company, satellite, startDate, endDate);
                }}
              >
                <option value={0}>--Run for all---</option>
                {clients?.map((name) => (
                  <option key={`client${name.value}`} value={name.value}>
                    {name.text}
                  </option>
                ))}
              </select>
              <select
                className="flex p-3 mx-1 mt-3 border md:mr-3 rounded-xl bg-gray-50"
                name="company"
                value={company}
                onChange={(oc) => {
                  let newCompanyId = parseInt(oc.target.value);
                  setCompany(newCompanyId);
                  updateDashboardParameters(client, newCompanyId, satellite, startDate, endDate);
                }}
              >
                <option value={0}>--Run for all---</option>
                {companies?.map((name) => (
                  <option key={`company${name.value}`} value={name.value}>
                    {name.text}
                  </option>
                ))}
              </select>
              <select
                className="flex p-3 mx-1 mt-3 border md:mr-3 rounded-xl bg-gray-50"
                name="satellite"
                value={satellite}
                onChange={(oc) => {
                  let newSatelliteId = parseInt(oc.target.value);
                  setSatellite(newSatelliteId);
                  updateDashboardParameters(client, company, newSatelliteId, startDate, endDate);
                }}
              >
                <option value={0}>--Run for all---</option>
                {satellites?.map((name) => (
                  <option key={`satellite${name.value}`} value={name.value}>
                    {name.text}
                  </option>
                ))}
              </select>
        </div>
      )}
    </DashboardContext.Consumer>
  );
};

export default DashboardDropdowns;