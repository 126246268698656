import fblogo from "src/images/fblogo.png";
import glogo from "src/images/glogo.png";
import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import {saveRespondentReview} from "../../shared/services/respondent-review-service";
import {getSatelliteReviewSite} from "../../shared/services/satellite-review-site-service";
const TyExpertsSimple = () => {
  const [googlePlacesUrl, setGooglePlacesUrl] = useState<string>();
  const [googlePlacesReviewSiteId, setGooglePlacesReviewSiteId] = useState<number>();
  const [facebookUrl, setFacebookUrl] = useState<string>();
  const [facebookReviewSiteId, setFacebookReviewSiteId] = useState<number>();
  const registerClick = async (reviewSiteId: number) =>{
    await saveRespondentReview({reviewSiteId: reviewSiteId});
  }

  const fetchSatelliteReviewSites = () =>{
    getSatelliteReviewSite('Google Places')
        .then(response=> {
          setGooglePlacesUrl(response.reviewLink);
          setGooglePlacesReviewSiteId(response.reviewSiteId);
        });

    getSatelliteReviewSite('Facebook')
        .then(response=> {
          setFacebookUrl(response.reviewLink);
          setFacebookReviewSiteId(response.reviewSiteId);
        });
  }

  useEffect(()=>{
    fetchSatelliteReviewSites();
  }, [])
  return (
    <>
      <div className="mt-8">
        <h2 className="text-3xl font-semibold text-center text-gray-800">
          Thank You!
        </h2>
    
        <div className="max-w-3xl mx-auto my-0">
          <div className="px-10 py-5 pb-5 mt-6 mb-5 bg-white rounded-lg shadow">
            <h2 className="block mb-2 text-2xl font-bold text-center text-gray-700 ">
            Please review us online
            </h2>
            <p className="text-center">
              Your contribution to one of these review sites may help other
              homeowners make a more informed decision. Click on a site below if
              you would like to post a review of Service Experts. We appreciate
              your support and look forward to serving you again!
            </p>
            <div className="flex justify-center mt-10">
              {(googlePlacesUrl && googlePlacesReviewSiteId) &&
                  <Link to={googlePlacesUrl}
                        target={"_blank"} onClick={()=> registerClick(googlePlacesReviewSiteId)}>
                    <div className="px-5">
                      <img
                          src={glogo}
                          alt="logo"
                          width="70px"
                          height="100%"
                      />
                      <h2 className="block mt-1 mb-2 text-lg font-bold text-center text-gray-700 ">
                        Google
                      </h2>
                    </div>
                  </Link>
              }

              {(facebookUrl && facebookReviewSiteId) &&
                  <Link to={facebookUrl}
                        target={"_blank"} onClick={()=> registerClick(facebookReviewSiteId)}>
                    <div className="px-5">
                      <img
                          src={fblogo}
                          alt="logo"
                          width="70px"
                          height="100%"
                      />
                      <h2 className="block mt-1 mb-2 text-lg font-bold text-center text-gray-700 ">
                        Facebook
                      </h2>
                    </div>
                  </Link>
              }              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TyExpertsSimple;
