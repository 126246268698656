import { useEffect } from "react";
import LoginForm from "src/components/Survey/LoginForm";
import clogo from "src/images/commendlogo.png";
const LoginPage = () => {
  useEffect(() => {
    document.title = 'Admin';
  }, []);
  return (
      <>
        <div className="h-screen pt-20 pb-10 bg-gradient-to-b from-white via-blue-100 to-blue-300">
          <div className="flex flex-col justify-center max-w-screen-lg mx-auto text-gray-600 mt-11">
            <div className="flex justify-center mx-auto">
              <img
                  src={clogo}
                  alt="logo"
                  width="170px"
                  height="100%"
              />
            </div>
            <h2 className="text-3xl font-bold text-center">Log in to your account</h2>
            <div className="mx-auto my-1 text-xl max-w-800">
              <p className="mb-6 text-center">
                In order to gain access to your account, please sign in.
              </p>
            </div>
          </div>

          <div>
            <LoginForm />
          </div>
        </div>
        <footer className="fixed bottom-0 left-0 right-0 flex items-center justify-center py-2 text-white bg-gray-900">
          <div className="flex justify-center mx-auto max-w-800">
            Powered By Imaginuity {new Date().getFullYear()}
          </div>
        </footer>
      </>
  );
};

export default LoginPage;
