import { useState } from "react";
import detail from "src/images/surveydetail.png";
export interface SurveyCountableItemProps {
  id: string;
  positiveCount: boolean;
  count: number;
  text: string;
  onChange(newValue: number): void;
  header?: string;
  leftLabel?: string;
  rightLabel?: string;
  isNA?: boolean;
}
const SurveyCountableItem = (props: SurveyCountableItemProps) => {
  const [active, setActive] = useState<number>();
  return (
    <div className={"w-full mb-4"}>
      {props.header && (
        <div className="mb-4 font-sans text-gray-700">{props.header}</div>
      )}
      <div className="mb-4 font-sans text-gray-700">{props.text}</div>
      <div className="flex items-center justify-center my-5">
        <img src={detail} alt="commend logo" width="auto" />
      </div>

      <div className="flex flex-row justify-between">
        {Array<number>(props.count)
          .fill(0)
          .map((_, i) => i + 1)
          .map((number) => {
            return (
              <div
                key={number}
                // style={{ background: active === number ? "red" : "white" }}
                className={
                  active === number
                    ? "justify-center items-center text-white bg-blue-600 flex md:h-10 md:w-8 rounded-full h-7 w-6"
                    : "flex md:h-10 md:w-8 h-7 w-6 rounded-full bg-slate-50 justify-center items-center cursor-pointer hover:bg-blue-600 hover:text-white"
                }
                onClick={() => {
                  props.onChange(number);
                  setActive(number);
                }}
              >
                {number}
              </div>
            );
          })}
        {props.isNA && (
          <div
            key={0}
            className={
              active === 0
                ? "justify-center items-center text-white bg-blue-600 flex md:h-10 md:w-8 rounded-full h-7 w-6"
                : "flex md:h-10 md:w-8 h-7 w-6 rounded-full bg-slate-50 justify-center items-center cursor-pointer hover:bg-blue-600 hover:text-white"
            }
            onClick={() => {
              props.onChange(0);
              setActive(0);
              
            }}
          >
            <span>

            N/A
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default SurveyCountableItem;
