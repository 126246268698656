import code from "src/images/enteredcode2.png";
import receipt from "src/images/receipt.png";
const HelpInfo = () => {
  return (
    <div className="text-base text-start">
      <ul>
        <li>
          <p className="">1.Find the receipt from your most recent service.</p>
        </li>
        <li>
          <p>
            2.Locate your Customer Code toward the bottom of the receipt, under
            the "Please Tell Us How We Did" Section"
            <br /> (See sample below).
          </p>
          <img src={receipt} alt="logo" width="100%" height="100%" />
        </li>
        <li>
          <p>
            3.Enter each set of digits in their respective text box. (See below
            for example).{" "}
          </p>
          <img src={code} alt="logo" width="500px" height="100%" />
        </li>
        <li>
          <p>
            4.Enter the remaining required information and click "Next" to be
            taken to the survey.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default HelpInfo;
