import { XCircleIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";
import DataTable, { TableColumn } from "react-data-table-component";
type Data = {
  satelliteCode: string;
  company: {
    companyName: string;
  };
  internalName: string;
  satelliteName: string;
  groupName: string;
  subGroupName: string;
};

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handlePageChange: (page: number) => void;
};
const PAGE_RANGE_DISPLAYED = 5;

const CustomMaterialPagination = ({
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  handlePageChange,
}: PaginationProps) => {
  const startPage = Math.max(
    1,
    currentPage - Math.floor(PAGE_RANGE_DISPLAYED / 2)
  );
  const endPage = Math.min(totalPages, startPage + PAGE_RANGE_DISPLAYED - 1);
  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <div className="flex justify-start mt-5 mb-6 ml-4 space-x-3 text-sky-600">
      <button disabled={currentPage <= 1} onClick={handlePreviousPage}>
        Previous
      </button>
      {startPage > 1 && (
        <>
          <button onClick={() => handlePageChange(1)}>1</button>
          <span>...</span>
        </>
      )}
      {pages.map((page) => (
        <button
          key={page}
          className={page === currentPage ? "text-slate-900" : ""}
          disabled={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ))}
      {endPage < totalPages && (
        <>
          <span>...</span>
          <button onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        </>
      )}
      <button disabled={currentPage >= totalPages} onClick={handleNextPage}>
        Next
      </button>
    </div>
  );
};

const SatellitesTable = ({ companyId, clientId, satelliteId }: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSatellite, setSelectedSatellite] = useState(null);
  const [satellitesData, setSatellitesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setPageItems] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedSatelliteForEdit, setSelectedSatelliteForEdit] =
    useState(null);
  const [sort, setSort] = useState<string | null>(null);

  const handleSort = (column: any, sortDirection: any) => {
    if (column?.name) {
      let columnName = column.name.toLowerCase().split(" ").join("_");
      setSort(`${columnName}_${sortDirection}`);
    }
  };

  const fetchData = async (
    companyId: string,
    itemsPerPage: number,
    currentPage: number,
    sort: string | null,
    satelliteId?: number
    
  ) => {
    setIsLoading(true);

    try {
      const response = await axios.get("/api/SatelliteDetail", {
        params: {
          clientId: clientId,
          companyId: companyId,
          satelliteId: satelliteId,
          Page: currentPage,
          ItemsPerPage: itemsPerPage,
          SortBy: sort,
        },
      });
    
      
      const paginationData = JSON.parse(response.headers["x-pagination"]);
      setTotalPages(paginationData.TotalPages);
      setSatellitesData(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(companyId, itemsPerPage, currentPage, sort, satelliteId);

  }, [companyId, currentPage, itemsPerPage, sort, satelliteId]);


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  //DELETE MODAL
  const handleOpenModal = (data: any) => {
    setSelectedSatellite(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSatellite(null);
    setModalOpen(false);
  };

  const handleDeleteSatellite = async (id: any, callback: () => void) => {
    setDeleteLoading(true);
    try {
      const response = await axios.delete(`/api/Satellite/${id}`);
      fetchData(companyId, itemsPerPage, currentPage, sort); // Refresh the data after deletion
      callback(); // Close the modal here
    } catch (error) {
      console.error(`Error deleting satellite with id: ${id}`, error);
    } finally {
      setDeleteLoading(false);
    }
  };

  //EDIT MODAL
  const handleOpenEditModal = (data: any) => {
    setSelectedSatelliteForEdit(data);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedSatelliteForEdit(null);
    setEditModalOpen(false);
  };

  // Pagination handlers
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const columns: TableColumn<Data>[] = [
    {
      name: "Satellite Code",
      selector: (row) => row.satelliteCode,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row.company.companyName,
      sortable: true,
    },
    {
      name: "Internal Name",
      selector: (row) => row.internalName,
      sortable: true,
    },
    {
      name: "Satellite Name",
      selector: (row) => row.satelliteName,
      sortable: true,
    },
    {
      name: "Group Name",
      selector: (row) => row.groupName,
      sortable: true,
    },
    {
      name: "Sub Group Name",
      selector: (row) => row.subGroupName,
      sortable: true,
    },
    {
      name: "Options",
      cell: (row: any) => (
        <>
          <button
            className="mr-2 text-sky-600 hover:text-blue-900"
            onClick={() => handleOpenEditModal(row)}
          >
            <PencilSquareIcon className="w-5 h-5" />
          </button>
          <button
            className="text-sky-600 hover:text-red-900"
            onClick={() => handleOpenModal(row)}
          >
            <XCircleIcon className="w-5 h-5" />
          </button>
        </>
      ),
    },
  ];

  if (isLoading) return <p>Loading...</p>; // Add a loading state
  
  return (
    <div className="flex flex-col">
      <DeleteModal
        isOpen={modalOpen}
        data={selectedSatellite}
        onClose={handleCloseModal}
        onDelete={handleDeleteSatellite}
        deleteLoading={deleteLoading}
      />
      <EditModal
        isOpen={editModalOpen}
        data={selectedSatelliteForEdit}
        onClose={handleCloseEditModal}
        fetchData={() => fetchData(companyId, itemsPerPage, currentPage, sort)}
      />

      <DataTable
        columns={columns}
        data={satellitesData}
        pagination
        paginationPerPage={itemsPerPage}
        onSort={handleSort}
        sortServer
        paginationComponent={() => (
          <CustomMaterialPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
          />
        )}
        paginationServer
      />
    </div>
  );
};

export default SatellitesTable;
