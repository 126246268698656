// In your SatelliteProvider component file

import { createContext, useState } from "react";

export const SatelliteContext = createContext({
  selectedSatelliteId: null,
  setSelectedSatelliteId: (id: any) => {}, 
  selectedCompanyId: null,
  setSelectedCompanyId: (id: any) => {},
});

export const SatelliteProvider = ({ children }:any) => {
  const [selectedSatelliteId, setSelectedSatelliteId] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  return (
    <SatelliteContext.Provider value={{ selectedSatelliteId, setSelectedSatelliteId, selectedCompanyId, setSelectedCompanyId }}>
      {children}
    </SatelliteContext.Provider>
  );
};
