import { Navigate, Route, Routes } from "react-router";
import React from "react";
import Homevestors from "./pages/homevestors";
import Serviceexperts from "./pages/serviceexperts";
import { BrowserRouter } from "react-router-dom";
import RouteChangeTracker from "./shared/route-change-tracker";
import Survey from "./pages/survey/[customer]";
import ThankyouSimple from "./pages/survey/[customer]/thankyousimple";
import Login from "./pages/admin/login";
import Dashboard from "./pages/admin/dashboard";
import SurveyHtml from "./pages/survey-html";
import OldHomevestors from "./pages/old-homevestors";
import Satellites from "./pages/admin/dashboard/satellites";
import Notifications from "./pages/admin/dashboard/notifications";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <RouteChangeTracker />
        <Routes>
          <Route path="/" element={<Navigate to="/serviceexperts" />} />
          <Route path="/serviceexperts" element={<Serviceexperts />} />
          <Route path="/c/serviceexpertssms" element={<Serviceexperts />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/thankyou" element={<ThankyouSimple />} />
          <Route path="/c/hv" element={<Homevestors />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/notifications" element={<Notifications />} />
          <Route path="/admin/satellites" element={<Satellites />} />
          <Route path="/survey-html" element={<SurveyHtml />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
