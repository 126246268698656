import HomevestorsLayout from "../layouts/homevestors-layout";
import HomeVestorsForm from "../components/Survey/HomeVestorsForm";
import { useEffect } from 'react';
const Homevestors =()=>{
    useEffect(() => {
        document.title = 'HomeVestors';
      }, []);
    return(
        <HomevestorsLayout>
            <HomeVestorsForm/>
        </HomevestorsLayout>
    )    
}
export default Homevestors;
