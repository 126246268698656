import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HomevestorsLayout from "../../../layouts/homevestors-layout";
import ServiceExpertsLayout from "../../../layouts/serviceexperts-layout";
import { HttpRequestError } from "../../../models/http-request-error";
import { HOMEVESTORS, SERVICEEXPERTS } from "../../../models/survey-company";
import { USER_TYPE_RESPONDENT } from "../../../models/user";
import { saveAnswer } from "../../../shared/services/respondent-survey-service";
import {
  getCurrentUser,
  isAuthenticated,
} from "../../../shared/services/session-service";
import {
  completeSurvey,
  getSurveyWithDetails,
} from "../../../shared/services/survey-service";
import SurveyCountableItem from "../../../shared/SurveyCountableItem";
import SurveyOpenItem from "../../../shared/SurveyOpenItem";

const Survey = () => {
  const [dataSource, setDataSource] = useState<any>();
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const user = getCurrentUser();
  useEffect(() => {
    async function getData() {
      try {
        const survey = await getSurveyWithDetails();
        setDataSource(survey);
      } catch (err) {
        if (err instanceof HttpRequestError) {
          swal.fire({
            text: err.message,
            toast: true,
            icon: "error",
            position: "top-right",
            timer: 3000,
          });
        }
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (!isAuthenticated(USER_TYPE_RESPONDENT)) {
      if (user?.companyId === HOMEVESTORS) {
        navigate("/c/hv");
      } else if (user?.companyId === HOMEVESTORS) {
        navigate("/serviceexperts");
      } else {
        navigate("/");
      }
    }
  });

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await completeSurvey();
      navigate("/thankyou");
      setIsLoading(false); // Set loading state back to false
    } catch (err) {
      setIsLoading(false); // Set loading state back to false
      if (err instanceof HttpRequestError) {
        swal.fire({
          text: err.message,
          toast: true,
          icon: "error",
          position: "top-right",
          timer: 2000,
        });
      }
    }
  };

  const submitAnswer = async (surveyDetailId: number, answer: string) => {
    setIsLoading(true); // Set loading state to true
    try {
      await saveAnswer({
        answer: answer,
        surveyDetailId: surveyDetailId,
      });
      setIsLoading(false); // Set loading state back to false
    } catch (err) {
      setIsLoading(false); // Set loading state back to false
      if (err instanceof HttpRequestError) {
        swal.fire({
          text: err.message,
          toast: true,
          icon: "error",
          position: "top-right",
          timer: 2000,
        });
      }
    }
  };

  const innerSurvey = () => {
    if (!dataSource?.surveyDetails) {
      return <div>Loading form..</div>;
    }

    return (
      <div className="w-full h-full mt-5">
        <div className="p-6 mx-auto bg-white rounded-md mb-28 md:max-w-xl">
          <div className="mb-8 text-xl font-bold text-center">
            Customer Satisfaction Survey
          </div>
          <Formik
            initialValues={{
              ...dataSource?.surveyDetails.reduce((values: any, item: any) => {
                values[item.surveyDetailId] = item.type === 1 ? "" : 0;
                return values;
              }, {}),
            }}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                {dataSource?.surveyDetails.map((item: any) => {
                  switch (item.type) {
                    case 1:
                      return (
                        <SurveyOpenItem
                          key={item.surveyDetailId}
                          id={item.surveyDetailId}
                          text={item.question}
                          onChange={(newValue) =>
                            submitAnswer(item.surveyDetailId, newValue)
                          }
                        ></SurveyOpenItem>
                      );
                    case 2:
                      const ratingData = {
                        count: 10,
                        leftLabel: "Very Unlikely",
                        rightLabel: "Very Likely",
                      };
                      return (
                        <div
                          key={item.surveyDetailId}
                          className="flex justify-center my-5 md:mb-5"
                        >
                          <Field name={`{items: ${item.surveyDetailId}}`}>
                            {({ form: { setFieldValue } }: any) => (
                              <SurveyCountableItem
                                key={item.surveyDetailId}
                                id={item.surveyDetailId}
                                positiveCount={true}
                                count={ratingData.count}
                                text={item.question}
                                header={item.header}
                                leftLabel={ratingData.leftLabel}
                                rightLabel={ratingData.rightLabel}
                                isNA={item.isNA}
                                onChange={(newValue) =>
                                  submitAnswer(
                                    item.surveyDetailId,
                                    newValue.toString()
                                  )
                                }
                              />
                            )}
                          </Field>
                        </div>
                      );
                  }
                })}
                <div
                          
                          className="flex justify-center my-5 mb-10 md:mb-8"
                        >
                <button
                  type="submit"
                  className={`
    w-full py-3 font-bold text-white uppercase 
    transition-colors rounded 
    bg-sky-600 hover:bg-sky-700
    ${isLoading ? "bg-sky-700 cursor-wait opacity-10" : ""}
  `}
                  disabled={isLoading}
                >
                  Submit
                </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };
  return (
    <>
      {user?.companyId === HOMEVESTORS && (
        <HomevestorsLayout>{innerSurvey()}</HomevestorsLayout>
      )}
      {user?.companyId === SERVICEEXPERTS && (
        <ServiceExpertsLayout>{innerSurvey()}</ServiceExpertsLayout>
      )}
    </>
  );
};

export default Survey;
