import { useEffect } from "react";
import ExpertsForm from "src/components/Survey/ExpertsForm";
import ServiceExpertsLayout from "../layouts/serviceexperts-layout";

const Serviceexperts = () => {
  useEffect(() => {
    document.title = "Service Experts";
  }, []);
  return (
    <ServiceExpertsLayout>
      <div className="mt-4 mb-3 text-center">
        <h2 className="max-w-lg mx-auto my-0 mb-3 text-3xl font-medium leading-9 md:text-4xl md:leading-10 md:mt-3">
          Please verify or update your contact information
        </h2>
        <p className="max-w-md mx-auto my-0 mb-6 md:mb-12 md:max-w-3xl">
          To ensure we have your most accurate contact information in our
          records, please verify or update the fields below. If possible, please
          enter your Technician or Residential Sales Consultant's name.
        </p>
      </div>
      <ExpertsForm />
    </ServiceExpertsLayout>
  );
};

export default Serviceexperts;
