export interface User {
    roleId?: number,
    clientId: number,
    companyId: string
    username: string,
    systemUserType: UserTypes,
    userId: any,
    exp: any,
    version: any
}
export const USER_TYPE_INTERNAL = 'internal';
export const USER_TYPE_RESPONDENT = 'respondent';
export type UserTypes = typeof USER_TYPE_INTERNAL | typeof USER_TYPE_INTERNAL