import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import MyLink from "./MyLink";
import {DashboardContext} from "../../pages/admin/admin-context";
import { useNavigate } from "react-router-dom";

const MyDropdown = () => {
  const navigate = useNavigate();
  const dashboard = [
    {
      name: "Survey Insights",
      disabled: false,
      dashboardId: 37,
      path: "/admin/dashboard"
    },
    // {
    //   name: "Referral Insights",
    //   disabled: false,
    //   href: "/admin/dashboard/referral",
    // },
    {
      name: "Review Insights",
      disabled: false,
      dashboardId: 38,
      path: "/admin/dashboard"
    },
  ];
  const reports = [
    {
      name: "Respondent",
      disabled: false,
      dashboardId: 39,
      path: "/admin/dashboard"
    },
    {
      name: "Respondent Positive",
      disabled: false,
      dashboardId: 40,
      path: "/admin/dashboard"
    },
    {
      name: "Respondent Incomplete",
      disabled: false,
      dashboardId: 47,
      path: "/admin/dashboard"
    },
    // {
    //   name: "Redemption",
    //   disabled: false,
    //   dashboardId: 42
    // },
    // {
    //   name: "Social Share",
    //   disabled: false,
    //   dashboardId: 43
    // },
  ];

  const satelites = [
    {
      name: "Satellites",
      disabled: false,
      path: "/admin/satellites"
    },
    {
      name: "Notifications",
      disabled: false,
      path: "/admin/notifications"
    }
  ]

  

  return (
      <DashboardContext.Consumer>
        {({updateDashboardId}) => (
            <div className="flex items-center justify-start w-full p-2 bg-gray-900">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="px-4 py-2 font-bold text-white rounded-md hover:bg-gray-600 active:bg-gray-700 focus:outline-none">
                  Dashboard
                </Menu.Button>
                <Transition
                    as={Fragment}
                    // show={isOpen}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 divide-y divide-gray-100">
                      {dashboard.map((item) => (
                          <Menu.Item key={item.name} disabled={item.disabled}>
                            {({ active }) => (
                                <MyLink
                                    disabled={item.disabled}
                                    active={active}
                                    onClick={ ()=> {
                                      navigate(item.path);
                                      updateDashboardId(item.dashboardId)}
                                    }
                                >
                                  {item.name}
                                </MyLink>
                            )}
                          </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="ml-5">
                <Menu as="div" className="relative inline-block text-left">
                  {/* <button onClick={() => setIsOpen(!isOpen)}>Dashboard</button> */}
                  <Menu.Button className="px-4 py-2 font-bold text-white rounded-md hover:bg-gray-600 active:bg-gray-700 focus:outline-none">
                    Reports
                  </Menu.Button>
                  {/* <button onClick={openClose}>Reports</button> */}
                  <Transition
                      as={Fragment}
                      // show={isOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 divide-y divide-gray-100">
                        {reports.map((item) => (
                            <Menu.Item key={item.name} disabled={item.disabled}>
                              {({ active }) => (
                                  <MyLink
                                      disabled={item.disabled}
                                      active={active}
                                      onClick={()=> {
                                        navigate(item.path);
                                        updateDashboardId(item.dashboardId)
                                      }}
                                  >
                                    {item.name}
                                  </MyLink>
                              )}
                            </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="ml-5">
                <Menu as="div" className="relative inline-block text-left">
                 
                  <Menu.Button className="px-4 py-2 font-bold text-white rounded-md hover:bg-gray-600 active:bg-gray-700 focus:outline-none">
                  Satellites
                  </Menu.Button>
      
                  <Transition
                      as={Fragment}
                      // show={isOpen}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                  >
                    <Menu.Items className="absolute z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 divide-y divide-gray-100">
                        {satelites.map((item) => (
                            <Menu.Item key={item.name} disabled={item.disabled}>
                              {({ active }) => (
                                  <MyLink
                                      disabled={item.disabled}
                                      active={active}
                                      onClick={() => navigate(item.path)}
                                  >
                                    {item.name}
                                  </MyLink>
                              )}
                            </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
        )}
      </DashboardContext.Consumer>
  );
};

export default MyDropdown;
