import { Link } from "react-router-dom";
import hvlogo from "src/images/hvlogo.png";

const HomevestorsLayout = ({ children }: any) => {
  return (
    <>
      <div
        style={{
          padding: "2rem",
          background: "#FFE01B",
          paddingBottom: "10px",
        }}
      >
        <div className="flex flex-col justify-center max-w-screen-lg mx-auto my-8 text-gray-700">
          <div className="flex justify-center mx-auto">
            <img
              src={hvlogo}
              alt="logo"
              width="170px"
              height="100%"
            />
          </div>
        </div>
        {children}
      </div>
      <footer className="fixed bottom-0 left-0 right-0 flex items-center justify-center py-4 text-white bg-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-center py-1 ">
            <Link to="https://www.webuyuglyhouses.com/privacy-policy">
              Privacy Policy
            </Link>
            <div className="ml-2">
              ©{new Date().getFullYear()} Homevestors LLC. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default HomevestorsLayout;
