import React from "react";

const DeleteNotificationModal = ({
  isOpen,
  data,
  onClose,
  onDelete,
  deleteLoading,
}: any) => {
  if (!isOpen || !data) {
    return null;
  }

  const handleDelete = () => {
    onDelete(data.satelliteNotificationId, onClose);
  };


  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto" role="dialog">
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div
            className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg font-bold leading-6 text-gray-900"
                  id="modal-headline"
                >
                  Confirm Satellite Notification Delete
                </h3>
                <div className="mt-5 text-left">
                  <p>
                    <strong>Satellite Code:</strong> {data.satellite.satelliteCode}
                  </p>
                  <p>
                    <strong>First Name:</strong> {data.firstName}
                  </p>
                  <p>
                    <strong>Last Name:</strong> {data.lastName}
                  </p>
                  <p>
                    <strong>Email Address:</strong> {data.emailAddress}
                  </p>
                  <p>
                    <strong>Exclude Positive:</strong>{" "}
                    {data.isPositiveNotification ? "Yes" : "No"}
                  </p>
                  <p>
                    <strong>Is Active:</strong> {data.isActive ? "Yes" : "No"}
                  </p>
                  <p>
                    <strong>Date Created:</strong>{" "}
                    {new Date(data.createDate).toLocaleString()}
                  </p>
                  {/* <p>
                    <strong>Created By:</strong> {data.createdBy}
                  </p> */}
                  <p>
                    <strong>Last Updated:</strong>{" "}
                    {new Date(data.updatedDate).toLocaleString()}
                  </p>
                  {/* <p>
                    <strong>Updated By:</strong> {data.updatedBy}
                  </p> */}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mr-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:border-slate-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`inline-flex justify-center w-full px-4 py-2 mx-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5 ${
                    deleteLoading
                      ? "bg-gray-500"
                      : "bg-red-600 hover:bg-red-500"
                  }`}
                  onClick={handleDelete}
                  disabled={deleteLoading} // Disable the button while loading
                >
                  {deleteLoading ? (
                    <>
                      <svg
                        className="w-5 h-5 mr-3 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Deleting...
                    </>
                  ) : (
                    "Delete"
                  )}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteNotificationModal;
