import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { getCompanies } from "../../../shared/services/internal-user-service";

interface FormData {
  companyId: number;
  internalName: string;
  satelliteName: string;
  satelliteCode: string;
  surveyIntro: string;
  groupName: string;
  subGroupName: string;
  isActive: boolean;
  userId: number;
}

const initialFormData: FormData = {
  companyId: 0,
  internalName: "",
  satelliteName: "",
  satelliteCode: "",
  surveyIntro: "",
  groupName: "",
  subGroupName: "",
  isActive: true,
  userId: 0,
};

interface AddSatelliteModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: any;
}

interface SelectComponentProps {
  items: { value: number; text: string }[];
  defaultText: string;
  onSelectChange: (value: number) => void;
}

const SelectComponent = ({
  items,
  defaultText,
  onSelectChange,
}: SelectComponentProps) => {
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    if (items?.length === 1) {
      setSelected(items[0].value);
      onSelectChange(items[0].value);
    }
  }, [items, onSelectChange]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let newValue = parseInt(event.target.value);
    setSelected(newValue);
    onSelectChange(newValue);
  };

  return (
    <select
      className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
      value={selected}
      onChange={handleSelectChange}
    >
      <option disabled value={0}>
        {defaultText}
      </option>
      {items.map((item, index) => (
        <option key={index} value={item.value}>
          {item.text}
        </option>
      ))}
    </select>
  );
};

const AddSatelliteModal = ({
  userId,
  isOpen,
  onClose,
}: AddSatelliteModalProps) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<number>(0);
  const [companies, setCompanies] = useState<any[]>([]);

  useEffect(() => {
    setFormData((formData) => ({ ...formData, userId }));
  }, [userId]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      companyId: company,
    }));
  }, [company]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyResponse] = await Promise.all([getCompanies()]);

        setCompanies(companyResponse);

        if (company !== 0) {
          const response = await axios.get("/api/Satellite/by-company", {
            params: { companyId: company },
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [company]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let value: string | number | boolean;

    if (e.target.type === "checkbox") {
      value = (e.target as HTMLInputElement).checked;
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post("/api/Satellite", formData);

      if (response.status === 200 || response.status === 201) {
        onClose();
        setFormData(initialFormData);
      }
    } catch (err) {
      // console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    // ...rest of the form...
    <div className="fixed inset-0 z-10 overflow-y-auto" role="dialog">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <form
          className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          onSubmit={handleSubmit}
        >
          <div className="mt-2">
            <h3 className="mb-3 text-lg font-bold leading-6 text-center text-gray-900">
              Create Satellite
            </h3>
            <div className="mt-3 text-left text-black sm:mt-5">
              <legend className="text-sm font-bold text-black">
                General Information
              </legend>
              <hr className="mt-2 mb-3 border-gray-300" />

              <label
                htmlFor="ctrl_Company_ID"
                className="text-right text-black "
              >
                Company:
              </label>
              <SelectComponent
                items={companies}
                defaultText="--Company---"
                onSelectChange={setCompany}
              />
              <label className="text-right text-black ">Satellite Code:</label>
              <input
                name="satelliteCode"
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                value={formData.satelliteCode}
                onChange={handleChange}
              />
              <label className="text-right text-black ">Satellite Name:</label>
              <input
                name="satelliteName"
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                value={formData.satelliteName}
                onChange={handleChange}
              />
              <label className="text-right text-black ">Internal Name:</label>
              <input
                name="internalName"
                className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                value={formData.internalName}
                onChange={handleChange}
              />
              <legend className="mt-5 text-sm font-bold text-black">
                Reporting Groups
              </legend>
              <hr className="mt-2 mb-3 border-gray-300" />
              <label>
                Group Name:
                <input
                  name="groupName"
                  value={formData?.groupName || ""}
                  onChange={handleChange}
                  className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                />
              </label>
              <label className="">
                Sub Group Name:
                <input
                  name="subGroupName"
                  value={formData?.subGroupName || ""}
                  onChange={handleChange}
                  className="w-full px-3 py-2 mt-1 text-base text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                />
              </label>
            </div>
            <div className="flex mt-5 sm:mt-6">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex justify-center w-full px-4 py-2 mr-2 text-base font-medium leading-6 text-black transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-slate-200 hover:bg-slate-300 focus:outline-none focus:border-slate-500 focus:shadow-outline-red sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={`inline-flex justify-center w-full px-4 py-2 mx-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm 
                      ${
                        isLoading
                          ? "bg-gray-500 cursor-not-allowed"
                          : "bg-sky-600 hover:bg-sky-700"
                      } 
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm`}
              >
                {isLoading ? (
                  <svg
                    className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

interface AddSatelliteButtonProps {
  userId: any;
}

const AddSatelliteButton = ({ userId }: AddSatelliteButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <button
        onClick={handleOpenModal}
        className="inline-flex items-center px-2 py-1 my-2 text-xs font-medium text-white border border-transparent rounded-md shadow-sm bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:px-4 sm:py-2 sm:text-sm"
      >
        Create Satellite
      </button>

      <AddSatelliteModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        userId={userId}
      />
    </>
  );
};

export default AddSatelliteButton;
